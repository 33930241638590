.SnackbarItem-variantSuccess {
    background-color: #D4F1BE !important;
    border: 1px solid #3D6552 !important;
    color: #000 !important;
}

.SnackbarItem-variantError {
    background-color: #F5DAE9 !important;
    border: 1px solid #C99797 !important;
    color: #000 !important;
}

.SnackbarItem-variantWarning {
    background-color: #FFE9BF !important;
    border: 1px solid #D9A05D !important;
    color: #000 !important;
}

.SnackbarItem-variantInfo {
    background-color: #63C3CF !important;
    border: 1px solid #63C3CF !important;
    color: #000 !important;
}