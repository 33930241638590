.analyticsCard{
    cursor: pointer;
    margin: 0;
}
.analyticsCard:hover{
    background-color: #add5d8;
    
}
.analyticsCard:hover .divider {
    border-color: #add5d8;
}

.selected-flag:hover, .selected-flag:focus {
    background-color: inherit !important;
}
/* .css-dqr9h-MuiRating-label {
    font-size: 25px;
} */


.css-1wy812t-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color: "#F4F5FA !important"
}

.desBox {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 500px) {
    /* overriding mobile css */
    .css-s6p79w {
        padding-left: 0 !important;
    }
    
}

@media (min-width: 576px) {
    .container {
        max-width: 100%;
    }
}
